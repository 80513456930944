import React, { useContext, useEffect, useRef, useState, MouseEvent } from 'react';
import useWindowWidth from 'hooks/use-window-width';
import Link from 'components/link';
import { buildClassName } from 'utils/build-class-name';
import Image from 'components/listing-image';
import ListingCardNewOverlay from 'components/listing-card-new-overlay';
import ListingStatus, { ListingStatusData } from 'components/listing-status';
import ListingBedBathSqftLabels, { ListingBedBathSqftLabelsData } from 'components/listing-card/listing-bed-bath-sqft-labels';
import FavouriteToggle from 'components/listing-card/favourite-toggle';
import ListingPrice, { ListingPriceData } from 'components/listing-price';
import ListingTimestamp, { ListingTimestampData } from 'components/listing-timestamp';
import AspectRatio from 'components/aspect-ratio';
import SchemaOrg from 'components/schema-org';
import { ModalContext, IModalContext } from 'contexts/modal';
import { UserContext, IUserContext } from 'contexts/user';
import { MapContext, IMapContext } from 'contexts/map';
import getImageSrc from 'utils/get-image';
import { listingCardIds } from 'constants/test-constants';
import getListingImageAlt from 'utils/image-alt-description';
import styles from './style.module.scss';
import { isListingVisibleToUser } from 'utils/listing-agreement-helper';
import ProviderLabels from './provider-labels';
import { GTM_CLICK_HOMEPAGE_SOLD_SIGN_IN } from 'constants/events';
import { trackEvent } from 'utils/google-tag-manager';
import ListingCardNewImageOverlay from './listing-card-new-image-overlay';
import ProviderLogo from './provider-logo';
import ConditionalWrapper from 'components/conditional-wrapper';
import { useIsMobile } from 'hooks/use-size-class';
import dayjs from 'dayjs';
import { locationsToNotUseListingModals } from 'constants/no-listing-modal-locations';
import generateFullAddress from 'utils/get-full-address';
import { ThemeNames } from 'types/themes';
import { useThemeContext } from 'contexts/theme';
import { ProvinceOrStateCode, isUsStateCode } from 'utils/province_or_state';

export interface ListingCardData extends ListingStatusData, ListingPriceData, ListingTimestampData, ListingBedBathSqftLabelsData {
  id: number;
  imageUrl: string | null;
  imageDesc: string | null;
  isImageReady: boolean;
  addressPathWithFallback: string;
  isCrea: boolean;
  isItsoVow: boolean;
  isPillar9Vow: boolean;
  isRebgvVow: boolean;
  city: string | null;
  province: string | null;
  isPrivate: boolean;
  isSold: boolean;
  position: {
    latitude: number;
    longitude: number;
  } | null;
  getStreet(isAuthenticated: boolean): string | null;
  providerId: number | null;
  isSignInAndTermsOfUseRequired: boolean;
  mlsNum: string | null;
  brokerage: string | null;
}

interface Props {
  listing: ListingCardData;
  listingToCompare?: ListingBedBathSqftLabelsData;
  isWidget?: boolean;
  isImageVisible?: boolean;
  isCrawler?: boolean;
  isHomepageAbTesting?: boolean;
  imageDescFallback?(listing: unknown): string;
  pvrSentDate?: string;
  gtmOnClickEvent?: string;
  isOnMapSidePanel?: boolean;
}

export default function ListingCard({ listing, listingToCompare, isWidget = false, isImageVisible, isCrawler, isHomepageAbTesting, imageDescFallback = getListingImageAlt, pvrSentDate, gtmOnClickEvent, isOnMapSidePanel = false }: Props) {
  const { id: listingId, imageUrl, imageDesc = '', isImageReady, addressPathWithFallback, isCrea, isPillar9Vow,
    city, province, isPrivate: listingIsPrivate, position, getStreet, providerId, isSignInAndTermsOfUseRequired } = listing;
  const { openModal } = useContext(ModalContext) as IModalContext;
  const { isAuthenticated, acceptedBrowserBasedTerms, acceptedTerms } = useContext(UserContext) as IUserContext;
  const [showsListing, setShowsListing] = useState<boolean>(false);
  const { highlightPin, unhighlightPin } = useContext(MapContext) as IMapContext;
  const windowWidth = useWindowWidth();
  const listingCardElement = useRef<HTMLDivElement>(null);
  const [isSmallCard, setIsSmallCard] = useState(true);
  const isPrivate = !isAuthenticated && listingIsPrivate;
  const imageAlt = imageDesc && imageDesc.length > 0 ? imageDesc : imageDescFallback(listing);
  const street = getStreet(isAuthenticated);
  const { themeName } = useThemeContext();
  const isZoocasa = themeName === ThemeNames.ZOOCASA;

  const shouldScramble = isPillar9Vow && !showsListing;

  const isNoModalLocation = province !== null && locationsToNotUseListingModals.includes(province);
  const isMobile = useIsMobile();
  const noModal = isNoModalLocation || isMobile;

  useEffect(() => {
    if (providerId) {
      setShowsListing(isListingVisibleToUser(isPrivate, providerId, acceptedBrowserBasedTerms, acceptedTerms ));
    }
  }, [isPrivate, providerId, acceptedBrowserBasedTerms, acceptedTerms]);

  const openListingModal = (event: MouseEvent) => {
    if (!noModal) {
      gtmOnClickEvent && trackEvent(gtmOnClickEvent);
      if (isWidget) {
        window.open(addressPathWithFallback, '_blank');
      } else {
        if ((event.target as Element).tagName !== 'A') {
          openModal('listing', { externalUrl: addressPathWithFallback, listingId });
          if (isPrivate || (isSignInAndTermsOfUseRequired && !showsListing && !isAuthenticated)) {
            if (isHomepageAbTesting) {
              trackEvent('Sold_Prices_Overlay');
              trackEvent(GTM_CLICK_HOMEPAGE_SOLD_SIGN_IN);
            }
          }
        }
      }
    }
  };


  const cardImageClass = buildClassName(styles.image, !showsListing && styles['blur-image'], !isZoocasa && styles.exp);
  const listingCardClass = buildClassName(styles.component, !isZoocasa && styles.exp);

  useEffect(() => {
    setIsSmallCard(listingCardElement.current!.offsetWidth < 280);
  }, [windowWidth]);

  const mouseEnterCard = () => {
    if (highlightPin) {
      highlightPin(listingId);
    }
  };

  const mouseLeaveCard = () => {
    if (unhighlightPin) {
      unhighlightPin();
    }
  };

  const trackNoModalEvents = () => {
    gtmOnClickEvent && trackEvent(gtmOnClickEvent);
    trackEvent('Xp_UiAddresspView');
  };

  const isHidden = isPrivate || (isSignInAndTermsOfUseRequired && !showsListing && !isAuthenticated);
  const isTermsRequired = !showsListing && (isCrea || isSignInAndTermsOfUseRequired);

  return (
    <ConditionalWrapper condition={noModal} wrapper={children => <Link href={addressPathWithFallback} target={noModal ? '_blank' : ''} onClick={trackNoModalEvents}>{children}</Link>}>
      <div className={listingCardClass} onMouseEnter={mouseEnterCard} onMouseLeave={mouseLeaveCard} ref={listingCardElement} onClick={openListingModal} data-testid={listingCardIds.listingCard} dynamic-testid={String(listingId)}>
        <div className={buildClassName(styles.row, !isZoocasa && styles['exp'])}>
          <AspectRatio ratio="4:3">
            <ConditionalWrapper condition={!noModal && isImageReady} wrapper={children => <Link href={addressPathWithFallback}>{children}</Link>}>
              <Image
                item={{ image: getImageSrc(imageUrl), alt: `${imageAlt} | Card Image`, id: `${listing.id}-card-image`, isImageReady, addedAt: listing.addedAt }}
                isVisible={isImageVisible}
                className={cardImageClass}
                testId={listingCardIds.listingImage}
                isCrawler={isCrawler}
                width={windowWidth}
                isListingCardImage={true}
              />
            </ConditionalWrapper>
            {isHidden &&
              <ListingCardNewImageOverlay isSmallCard={isSmallCard} isImageReady={isImageReady} />
            }
            {pvrSentDate && <p className={styles['pvr-date']}>Request sent on {dayjs(pvrSentDate).format('MMM DD, YYYY')}</p>}
          </AspectRatio>
        </div>
        <div className={styles.wrapper}>
          {isHidden || (isTermsRequired && isAuthenticated) || (isTermsRequired && isCrea)
            ? <ListingCardNewOverlay isLoginRequired={isHidden} isTermsRequired={isTermsRequired} isSmallCard={isSmallCard} isCrea={isCrea} />
            : <div className={styles['bottom-content']}>
              <div>
                {showsListing && <ListingStatus listing={listing} className={styles.tag} />}
                {showsListing && !isWidget && <FavouriteToggle listingId={listingId} />}
                <div className={styles.row} data-testid={listingCardIds.listingCardPrice}>
                  <ListingPrice listing={listing} isVisible={showsListing} isMinimal={true} testId={listingCardIds.listingPrice} shouldScramble={shouldScramble} />
                  <ListingTimestamp listing={listing} isSmallCard={isSmallCard} isMinimal={true} className={styles['date-added']} testId={listingCardIds.listingTimeStamp} isVisible={showsListing} shouldScramble={shouldScramble} />
                </div>
                <div className={styles.row}>
                  <SchemaOrg itemType="SingleFamilyResidence">
                    <SchemaOrg itemProp="address" itemType="PostalAddress">
                      {noModal ?
                        <div className={styles['street-address']} data-testid={listingCardIds.listingAddress}>
                          <p>{generateFullAddress(street, city, province, shouldScramble)}</p>
                        </div> :
                        <p className={styles['street-address']} data-testid={listingCardIds.listingAddress}>
                          <Link href={addressPathWithFallback} target="_blank" onClick={() => sessionStorage.setItem('scroll-position', JSON.stringify({ position: window.scrollY, url: window.location.pathname }))}>
                            {generateFullAddress(street, city, province, shouldScramble)}
                          </Link>
                        </p>
                      }
                      <meta itemProp="streetAddress" content={street || ''} />
                      <meta itemProp="addressLocality" content={city || ''} />
                      <meta itemProp="addressRegion" content={province || ''} />
                    </SchemaOrg>
                    <SchemaOrg itemProp="geo" itemType="GeoCoordinates">
                      <meta itemProp="latitude" content={position ? position.latitude.toString() : ''} />
                      <meta itemProp="longitude" content={position ? position.longitude.toString() : ''} />
                    </SchemaOrg>
                  </SchemaOrg>
                </div>
                <ListingBedBathSqftLabels listing={listing} listingToCompare={listingToCompare} isPrivate={isHidden} requiresTerms={isTermsRequired} />
              </div>
              <div>
                <ProviderLabels
                  isBlurred={isHidden || isTermsRequired}
                  hasLogo={isUsStateCode(province?.toLowerCase() as ProvinceOrStateCode)}
                  brokerage={listing.brokerage}
                  mlsNum={listing.mlsNum}
                />
                <ProviderLogo listing={listing} isOnMapSidePanel={isOnMapSidePanel} />
              </div>
            </div>
          }
        </div>
      </div>
    </ConditionalWrapper>
  );
}